<template>
  <section class="landing-join-artist__wrapper">
    <figure class="landing-join-artist__image-wrapper">
      <img
        src="https://www.defense-92.fr/wp-content/uploads/2020/05/T6JB.jpg"
        alt=""
        class="landing-join-artist__image"/>
    </figure>

    <header class="landing-join-artist__header">
      <Heading
        :level="'h2'"
        :tag="'h3'"
        :color="'white'">
        {{ $t('artist.dashboard.landing.artistJoin.title') }}
      </Heading>
    </header>

    <ul class="landing-join-artist__perks-list">
      <Heading
        v-for="(perk, index) in perks"
        :level="'h5'"
        :tag="'li'"
        :color="'white'"
        :key="`perk-${index}`"
        class="landing-join-artist__perks-item">
         • {{ perk.label }}
      </Heading>
    </ul>

    <Button
      isWhite
      hasNoBorder
      class="landing-join-artist__join-cta">
      <router-link :to="{ name: 'ArtistSignup' }">
        {{ $t('artist.dashboard.landing.artistJoin.cta') }}
      </router-link>
    </Button>
  </section>
</template>

<script>

import Heading      from '../../../components/atoms/Heading/a-Heading.vue';
import Button       from '../../../components/atoms/Button/a-Button.vue';


export default {
  name: 'o-LandingJoinArtist',
  components: {
    Heading,
    Button,
  },
  computed: {
    perks() {
      return [
        {
          label: this.$t('artist.dashboard.landing.artistJoin.perks.freeSignup'),
        },
        {
          label: this.$t('artist.dashboard.landing.artistJoin.perks.universe'),
        },
        {
          label: this.$t('artist.dashboard.landing.artistJoin.perks.ownAgent'),
        },
      ];
    },
  },
}

</script>

<style lang="scss">

.landing-join-artist {
  &__wrapper {
    z-index: 1;
    position: relative;
    grid-row: 5 / 6;
    display: grid;
    grid-template-rows: repeat(3, min-content);
    align-content: center;
    min-height: 400px;
    max-height: 400px;
    margin: var(--space-base);
    padding: 0 var(--space-lg);
    font-family: var(--font-stack-secondary);

    @media screen and ($desktop) {
      grid-template-columns: 1fr auto;
      grid-template-rows: 3fr 2fr;
      align-content: initial;
      min-height: 250px;
      max-height: 250px;
      margin: var(--space-lg) var(--space-xl);
      padding-left: var(--space-xl);
    }
  }

  &__header {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
    align-self: end;
    margin-bottom: var(--space-base);
    transform: translateZ(0);
  }

  &__image-wrapper {
    z-index: -1;
    position: absolute;
    top: 50%;
    width: 100%;
    height: 350px;
    transform: translateY(-50%);

    &:after {
      content: '';
      position: absolute;
      top: 0;
      background-color: hsla(0, 0%, 0%, .25);
      width: 100%;
      height: 100%;
      border-radius: var(--rounded-xs);
    }

    @media screen and ($desktop) {
      height: 200px;
    }
  }

  &__image {
    min-height: 100%;
    max-height: 100%;
    min-width: 100%;
    object-fit: cover;
    border-radius: var(--rounded-xs);

    @media screen and ($desktop) {
      min-height: auto;
    }
  }

  &__perks-list {
    align-self: start;
    grid-column: 1 / 2;
    grid-row: 2 / 3;
    display: flex;
    flex-direction: column;
    row-gap: var(--space-md);
    margin-bottom: var(--space-lg);

    @media screen and ($desktop) {
      flex-direction: row;
      align-items: center;
      margin-bottom: 0;
    }
  }

  &__perks-item {
    &:not(:last-child) {
      margin-right: var(--space-xl);
    }
  }

  &__join-cta {
    grid-row: 3 / 4;
    align-self: center;

    @media screen and ($desktop) {
      grid-row: 1 / -1;
    }
  }
}

</style>
