<template>
  <section class="landing-categories__wrapper">
    <header class="landing-categories__header">
      <Heading
        :level="'h2'"
        :tag="'h2'"
        :class="{ 'mb-base': !$mediaQueries.isDesktop }">
        {{ $t('artist.dashboard.landing.categories.heading') }}
      </Heading>
      <div class="landing-categories__arrows-wrapper">
        <Icon
          @click="() => $refs.flickity && $refs.flickity.previous()"
          :variant="'arrow-left-outline'"
          :size="'xs'"
          :color="'white'"
          :tag="'button'"
          :backdropColor="'dark'"
          type="button"
          class="ml-auto mr-md">
        </Icon>
        <Icon
          @click="() => $refs.flickity && $refs.flickity.next()"
          :variant="'arrow-right-outline'"
          :size="'xs'"
          :color="'white'"
          :tag="'button'"
          :backdropColor="'dark'"
          type="button"
          class="ml-base">
        </Icon>
      </div>
    </header>

    <Flickity
      :options="flickityOptions"
      ref="flickity"
      class="landing-categories__categories-list">
      <div
        v-for="(category, index) in categories"
        :key="`category-${index}`"
        class="landing-categories__categories-item">
        <figure class="landing-categories__categories-item-wrapper">
          <button
            @click="handleActivtiyClick(category)"
            type="button">
            <img
              :src="category.image"
              alt="Categorie artiste"
              class="landing-categories__categories-item-image">
            <Paragraph
              :tag="'figcaption'"
              :size="'md'"
              isOverflow>
              {{ category.label }}
            </Paragraph>
          </button>
        </figure>
      </div>
    </Flickity>
  </section>
</template>

<script>

import {
  mapMutations,
}                               from 'vuex';
import Flickity                 from 'vue-flickity';

import Heading                  from '../../../components/atoms/Heading/a-Heading.vue';
import Icon                     from '../../../components/atoms/Icon/a-Icon.vue';
import Paragraph                from '../../../components/atoms/Paragraph/a-Paragraph.vue';
import FLICKITY_OPTIONS         from '../../../constants/flickity-options.js';
import ACTIVITY_OPTIONS         from '../../../constants/activity-options.js';


export default {
  name: 'o-LandingActivities',
  components: {
    Flickity,
    Heading,
    Icon,
    Paragraph,
  },
  data: () => ({
    flickityOptions: FLICKITY_OPTIONS,
    activityOptions: ACTIVITY_OPTIONS,
  }),
  computed: {
    categories() {
      return this.activityOptions.map((activity) => ({
        ...activity,
        label: this.$t(activity.label),
      }));
    },
  },
  methods: {
    ...mapMutations({
      setActiveFilter: 'SearchArtist/SET_ACTIVE_FILTER',
      clearSearch: 'SearchArtist/CLEAR_SEARCH',
    }),
    handleActivtiyClick(activity) {
      this.clearSearch();
      this.setActiveFilter({ name: 'activity', payload: [activity.value] });
      this.$router.push({ name: 'ClientDashboard' });
    },
  },
}

</script>

<style lang="scss">

.landing-categories {
  &__wrapper {
    grid-row: 2 / 3;
    padding: var(--space-xl) var(--space-lg);
    padding-top: var(--space-lg);
    padding-bottom: var(--space-base);

    @media screen and ($desktop) {
      padding: var(--space-xl) var(--space-xxl);
    }
  }

  &__header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    row-gap: var(--space-md);
    margin-bottom: var(--space-lg);

    @media screen and ($desktop) {
      flex-direction: row;
      align-items: center;
    }
  }

  &__categories-list {
    margin: 0 calc(-1 * var(--space-lg));

    @media screen and ($desktop) {
      margin: 0 calc(-1 * var(--space-xxl));
    }
  }

  &__categories-item {
    display: flex;
    flex-direction: column;
    margin-right: var(--space-lg);
  }

  &__categories-item-wrapper {
    width: 175px;
  }

  &__categories-item-image {
    height: 175px;
    object-fit: cover;
    margin-bottom: var(--space-sm);
    border-radius: var(--rounded-xl);
  }

  &__arrows-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: var(--space-xs);

    @media screen and ($desktop) {
      margin-left: auto;
    }
  }
}

</style>
