<template>
  <MainLayout
    isFullScreen
    class="main-layout--landing-page">
      <Loader
        v-if="isLoading"
        class="m-auto">
      </Loader>
      <template v-else>
        <LandingSearchHeader :banners="landingBanners"></LandingSearchHeader>
        <LandingActivities></LandingActivities>
        <LandingShowreel :showreelURL="landingData.VideoURL"></LandingShowreel>
        <LandingArtistsList :artists="featuredArtists"></LandingArtistsList>
        <LandingJoinArtist></LandingJoinArtist>
        <LandingTestimonials :comments="landingComments"></LandingTestimonials>
      </template>
  </MainLayout>
</template>

<script>

import {
  mapState,
  mapGetters,
  mapActions,
}                               from 'vuex';

import MainLayout               from '../../../components/layout/MainLayout/l-MainLayout.vue';
import Loader                   from '../../../components/atoms/Loader/a-Loader.vue';
import LandingActivities        from './o-LandingActivities.vue';
import LandingSearchHeader      from './o-LandingSearchHeader.vue';
import LandingShowreel          from './o-LandingShowreel.vue';
import LandingArtistsList       from './o-LandingArtistsList.vue';
import LandingJoinArtist        from './o-LandingJoinArtist.vue';
import LandingTestimonials      from './o-LandingTestimonials.vue';
import {
  getCroppedPictureURL
}                               from '../../../utils/URLUtils.js';


export default {
  name: 'p-Landing',
  components: {
    MainLayout,
    Loader,
    LandingActivities,
    LandingSearchHeader,
    LandingShowreel,
    LandingArtistsList,
    LandingJoinArtist,
    LandingTestimonials,
  },
  data: () => ({
    isLoading: false,
  }),
  computed: {
    ...mapState('Landing', ['landingData']),
    ...mapGetters({
      featuredArtists: 'Landing/FEATURED_ARTISTS_PROFILES',
    }),
    landingBanners() {
      return this.landingData?.BannerArtists?.map(
        ({ MediaURL, MediaURLCropOption, ...artist }) =>
        ({ ...artist, MediaURL: this.getCroppedPictureURL(MediaURL, MediaURLCropOption )}))
        ?? [];
    },
    landingComments() {
      return Object.values(this.landingData?.Comments ?? {});
    },
  },
  async created() {
    await this.getLandingData();
  },
  methods: {
    getCroppedPictureURL,
    ...mapActions({ getLanding: 'Landing/GET_LANDING' }),
    async getLandingData() {
      try {
        this.isLoading = true;

        await this.getLanding();
      } finally {
        this.isLoading = false;
      }
    },
  }
}

</script>
