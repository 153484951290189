<template>
  <form
    @submit.prevent="$router.push({ name: 'ClientDashboard' })"
    @click="handleClick"
    novalidate
    :class="[
      'client-search-bar__wrapper',
      { 'client-search-bar__wrapper--landing': isLanding },
      { 'client-search-bar__wrapper--label': isShowLabel },
    ]">
    <header
      v-if="isShowLabel"
      class="client-search-bar__header">
      <Heading
        :tag="'h4'"
        :level="'h4'"
        :color="isLanding ? 'white' : 'black'">
        {{ $t('artist.dashboard.landing.searchHeader.findAnArtist') }}
      </Heading>
    </header>

    <template v-if="isLanding && !$mediaQueries.isDesktop">
      <button
        @click="handleMobileSearchClick"
        class="client-search-bar__mobile-search-wrapper"
        type="button">
        <input
          :value="filterQuery"
          :placeholder="$t('artist.dashboard.landing.searchHeader.findAnArtist')"
          type="text"
          id="mobile-search"
          class="client-search-bar__mobile-search-input"
        />
        <Icon
          :variant="'search-outline'"
          :color="'white'"
          :size="'xs'"
          :backdropColor="'black'">
        </Icon>
      </button>
    </template>

    <div
      v-else
      class="client-search-bar__inputs-wrapper">
      <div
        @focusout="event => isShowKeywords = $refs.keywords.contains(event.relatedTarget)"
        ref="keywords"
        class="client-search-bar__keywords-wrapper">
        <InstantSearch>
          <ais-refinement-list
            :attribute="'tags'"
            :sort-by="['count:desc']">
            <template #default="{ searchForItems, items }">
              <div class="
                client-search-bar__input-wrapper
                client-search-bar__input-wrapper--keywords
              ">
                <Icon
                  :variant="'search-outline'"
                  :size="$mediaQueries.isDesktop ? 'sm' : 'md'"
                  class="ml-md">
                </Icon>
                <input
                  v-model="keywordInput"
                  @focus="isShowKeywords = true"
                  @input="event => searchForItems(event.target.value)"
                  :placeholder="$t('artist.dashboard.landing.searchHeader.findAnArtist')"
                  ref="keyword-input"
                  type="text"
                  class="client-search-bar__input"
                />
                <Icon
                  v-if="keywordInput"
                  @click.stop="handleKeywordClear"
                  :variant="'cross-outline'"
                  :tag="'button'"
                  :color="'neutral'"
                  :size="'xs'"
                  type="reset"
                  class="mr-md">
                </Icon>
              </div>
              <Dropdown
                v-if="isShowKeywords"
                :tag="'ol'">
                <template>
                  <li
                    v-for="(activity, index) in keywordInput ? filteredActivities : activityOptions"
                    @mousedown.prevent="handleKeywordSelect($t(activity.label))"
                    :key="`activity-${index}`"
                    class="client-search-bar__keywords-results-item">
                    <Paragraph
                      :tag="'button'"
                      isBold
                      type="button">
                      {{ $t(activity.label) }}
                    </Paragraph>
                  </li>
                  <template v-if="keywordInput && keywordInput.trim().length">
                    <li
                      v-for="(item, index) in items"
                      @mousedown.prevent="handleKeywordSelect(item.value)"
                      :key="`item-${index}`"
                      class="client-search-bar__keywords-results-item">
                      <Paragraph
                        :tag="'button'"
                        isBold
                        isOverflow
                        type="button">
                        {{ item.value }}
                      </Paragraph>
                    </li>
                  </template>
                </template>
              </Dropdown>
            </template>
          </ais-refinement-list>
        </InstantSearch>
      </div>
      <GoogleLocationFinder
        @focusout.native="event => isShowLocation = $refs.location.$el.contains(event.relatedTarget)"
        @place-selected="handleLocationSelect"
        ref="location">
        <template #input="{ handleSearchLocation }">
          <div class="client-search-bar__input-wrapper">
            <Icon
              :variant="'location-solid'"
              :size="$mediaQueries.isDesktop ? 'sm' : 'md'"
              class="ml-md">
            </Icon>
            <input
              v-model="locationInput"
              @focus="isShowLocation = true"
              @input="event => handleSearchLocation(event.target.value)"
              :placeholder="$t('artist.dashboard.landing.searchHeader.eventLocation')"
              ref="location-input"
              type="text"
              class="client-search-bar__input"
            />
            <Icon
              v-if="locationInput"
              @click="
                handleLocationClear();
                handleSearchLocation('');
              "
              :variant="'cross-outline'"
              :tag="'button'"
              :color="'neutral'"
              :size="'xs'"
              type="reset"
              class="mr-md">
            </Icon>
          </div>
        </template>
        <template #results="{ predictions, handleSelectPrediction }">
          <Dropdown
            v-if="isShowLocation"
            :tag="'ol'">
            <li
              v-for="(prediction, index) in predictions"
              :key="`prediction-${index}`"
              class="client-search-bar__location-results-item">
              <button
                @mousedown="handleSelectPrediction(prediction)"
                type="button"
                class="client-search-bar__location-results-item-button">
                <Icon
                  :variant="'location-solid'"
                  :color="'neutral'"
                  class="mr-xs">
                </Icon>
                <span class="
                  client-search-bar__location-text
                  client-search-bar__location-text--main
                ">
                  {{ prediction.mainText }}
                </span>
                <span class="
                  client-search-bar__location-text
                  client-search-bar__location-text--secondary
                ">
                  {{ prediction.secondaryText }}
                </span>
              </button>
            </li>
          </Dropdown>
          <span v-else></span>
        </template>
      </GoogleLocationFinder>

      <div
        @focusout="event => isShowCalendar = $refs.calendar.contains(event.relatedTarget)"
        ref="calendar"
        class="client-search-bar__calendar-wrapper">
        <div class="
          client-search-bar__input-wrapper
          client-search-bar__input-wrapper--calendar
        ">
          <Icon
            :variant="'calendar-outline'"
            :size="$mediaQueries.isDesktop ? 'sm' : 'md'"
            class="ml-md">
          </Icon>
          <button
            @click.prevent="isShowCalendar = true"
            @focus="isShowCalendar = true"
            type="button"
            ref="calendar-input"
            class="client-search-bar__input">
            <Paragraph
              v-if="dateInput"
              class="client-search-bar__date">
              {{ formattedDate }}
            </Paragraph>
            <span
              v-else
              class="placeholder mr-auto">
              {{ $t('artist.dashboard.client.booking.details.chooseDate') }}
            </span>
            <Icon
              v-if="dateInput"
              @click="handleDateClear"
              :variant="'cross-outline'"
              :tag="'button'"
              :color="'neutral'"
              :size="'xs'"
              type="reset"
              class="ml-auto">
            </Icon>
          </button>
        </div>
        <AgendaCalendar
          v-if="isShowCalendar"
          @availability-selected="handleAvalaibilitySelected"
          @mousedown.native.prevent
          @month-changed="handleFetchArtistPublicAvailabilities"
          :availabilities="selectedAvailabilities"
          :startDate="selectedAvailabilityStartDate"
          isPublic
          isWhiteBackground
          class="client-search-bar__calendar">
        </AgendaCalendar>
      </div>
      <div
        v-if="$mediaQueries.isDesktop"
        class="client-search-bar__search-button">
        <Icon
          :variant="'search-outline'"
          :tag="'button'"
          :size="'xs'"
          :color="'white'"
          :backdropColor="'gradient'"
          type="submit">
        </Icon>
      </div>
    </div>
  </form>
</template>

<script>

import {
  mapState,
  mapGetters,
  mapMutations,
  mapActions,
}                               from 'vuex';
import debounce                 from 'lodash.debounce';

import Icon                     from '../../../components/atoms/Icon/a-Icon.vue';
import Paragraph                from '../../../components/atoms/Paragraph/a-Paragraph.vue';
import Heading                  from '../../../components/atoms/Heading/a-Heading.vue';
import Dropdown                 from '../../../components/molecules/Dropdown/m-Dropdown.vue';
import AgendaCalendar           from '../../../components/molecules/AgendaCalendar/m-AgendaCalendar.vue';
import GoogleLocationFinder     from '../../../components/molecules/GoogleLocationFinder/m-GoogleLocationFinder.vue';
import InstantSearch            from '../../../components/molecules/InstantSearch/m-InstantSearch.vue';
import ACTIVITY_OPTIONS         from '../../../constants/activity-options.js';
import {
  getDateAsDDMMYYYY,
}                               from '../../../utils/dateUtils.js';
import {
  getMatchingWordInList,
}                               from '../../../utils/stringUtils.js';


export default {
  name: 'ClientSearchBar',
  components: {
    Icon,
    Heading,
    Paragraph,
    Dropdown,
    AgendaCalendar,
    GoogleLocationFinder,
    InstantSearch,
  },
  props: {
    isLanding: {
      type: Boolean,
      default: false,
    },
    isShowLabel: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    activityOptions: ACTIVITY_OPTIONS,
    isShowKeywords: false,
    isShowLocation: false,
    isShowCalendar: false,
    keywordInput: '',
    locationInput: '',
    dateInput: '',
    latitude: '',
    longitude: '',
    debouncedHandleKeywordInput: () => {},
  }),
  computed: {
    ...mapState('SearchArtist', ['currentArtist', 'filters', 'filterQuery', 'selectedAvailability']),
    ...mapGetters({
      artistAvailabilities: 'SearchArtist/CALENDAR_AVAILABILITIES',
      selectedAvailabilities: 'SearchArtist/CALENDAR_AVAILABILITIES_FILTER',
    }),
    filteredActivities() {
      return getMatchingWordInList(this.keywordInput, this.activityOptions, ({ label }) => this.$t(label));
    },
    formattedDate() {
      const date = this.dateInput || this.selectedAvailability.timestamp;

      return date
        ? this.getDateAsDDMMYYYY(new Date(date))
        : '';
    },
    selectedAvailabilityStartDate() {
      if (!this.selectedAvailability?.timestamp) return;

      const date = new Date(this.selectedAvailability.timestamp);
      const startDate = {
        month: date.getMonth() + 1,
        year: date.getFullYear(),
      };

      return startDate;
    },
  },
  created() {
    this.prefillFields();
    this.debouncedHandleKeywordInput = debounce(this.handleKeywordInput, 500);
  },
  watch: {
    keywordInput(input) {
      this.debouncedHandleKeywordInput(input);
    },
  },
  methods: {
    ...mapMutations({
      setClientFilter: 'SearchArtist/SET_ACTIVE_FILTER',
      setClientFilterQuery: 'SearchArtist/SET_FILTER_QUERY',
      setCurrentAvailability: 'SearchArtist/SET_CURRENT_AVAILABILITY',
      toggleMobileFiltersType: 'Layout/TOGGLE_MOBILE_FILTERS_TYPE',
    }),
    ...mapActions({
      fetchArtistPublicAvailabilities: 'SearchArtist/FETCH_ARTIST_PUBLIC_AVAILABILITIES',
    }),
    getDateAsDDMMYYYY,
    prefillFields() {
      this.locationInput = this.filters?.localisation?.formatted_address ?? '';
      this.dateInput = this.selectedAvailability?.timestamp ?? '';
      this.keywordInput = this.filterQuery || '';
    },
    handleLocationSelect(place) {
      const geometry = {
        ...place.geometry,
        location: {
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        },
      };

      this.locationInput = place.formatted_address;
      this.setClientFilter({ name: 'localisation', payload: { ...place, geometry } });
      this.$refs?.['calendar-input']?.focus();
    },
    async handleFetchArtistPublicAvailabilities(date) {
      if (!this.currentArtist.ID) return;

      const params = {
        input: {
          artistID: this.currentArtist.ID,
          date,
        },
      };

      await this.fetchArtistPublicAvailabilities(params);
    },
    handleAvalaibilitySelected(availability) {
      const { EventDate, timestamp } = availability;

      this.setClientFilter({ name: 'availability', payload: [EventDate] });
      this.setCurrentAvailability(availability);
      this.isShowCalendar = false;
      this.dateInput = timestamp;
    },
    handleClick(event) {
      this.isShowCalendar = Boolean(this.$refs?.calendar?.contains(event.target));
      this.isShowKeywords = Boolean(this.$refs?.keywords?.contains(event.target));
    },
    handleKeywordInput(keyword) {
      // Matches complete user sentence + every individual word
      const trimmedKeyword = keyword.trim();
      const individualKeywords = keyword?.split(' ')?.filter(Boolean) ?? [];
      const tagsFilter = [
        ...(trimmedKeyword ? [`"${trimmedKeyword}"`] : []),
        ...(individualKeywords?.length > 1 ? individualKeywords.map(keyword => `"${keyword}"`) : []),
      ];

      this.setClientFilter({ name: 'tags', payload: tagsFilter });
      this.setClientFilterQuery(keyword);
    },
    handleKeywordClear() {
      this.keywordInput = '';
      this.$refs?.['keyword-input']?.focus?.();
    },
    handleKeywordSelect(keyword) {
      this.keywordInput = keyword;
      this.isShowKeywords = false;
      this.$refs?.['location-input']?.focus?.();
    },
    handleLocationClear() {
      this.setClientFilter({ name: 'localisation', payload: {} });
      this.locationInput = '';
    },
    handleDateClear() {
      this.setClientFilter({ name: 'availability', payload: [] });
      this.setCurrentAvailability({});
      this.dateInput = '';
    },
    handleMobileSearchClick() {
      this.toggleMobileFiltersType('primary');
      this.$router.push({ name: 'ClientDashboard' });
    },
  },
}

</script>

<style lang="scss">

@mixin inputSeparator($right: 0) {
  @media screen and ($desktop) {
    &:after {
      content: '';
      position: absolute;
      top: 50%;
      right: $right;
      height: 50%;
      width: 1px;
      background-color: var(--color-grey-empty);
      transform: translateY(-50%);
    }
  }
}

.client-search-bar {
  &__wrapper {
    display: flex;
    flex-direction: column;
    row-gap: var(--space-md);

    @media screen and ($desktop) {
      position: relative;
      top: 2px;
      flex: 1 1 auto;
      margin: 0 var(--space-lg);
    }

    &--landing {
      row-gap: var(--space-sm);
      min-width: 90%;
      max-width: 1400px;

      @media screen and ($desktop) {
        margin: 0 auto;
      }

      .client-search-bar__input-wrapper {
        min-height: 60px;
        max-height: 60px;
      }

      .client-search-bar__search-button {
        padding: 0 var(--space-md);
      }
    }
  }

  &__header {
    grid-column: 1 / -1;
  }

  &__mobile-search-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    width: 100%;
    padding-right: var(--space-md);
    padding-left: var(--space-lg);
    border-radius: var(--rounded-xl);
    font-family: var(--font-stack-secondary);
    background-color: var(--color-grey-light);
  }

  &__mobile-search-input {
    height: 100%;
    flex: 1 1 auto;
    background-color: transparent;

    &::placeholder {
      position: relative;
      top: -2px;
      font-size: var(--text-xxs);
      font-style: italic;
      color: var(--color-grey-neutral);
    }
  }

  &__inputs-wrapper {
    display: flex;
    flex-direction: column;
    row-gap: var(--space-md);

    @media screen and ($desktop) {
      display: grid;
      grid-template-columns: repeat(3, 1fr) auto;
      align-items: center;
      width: 100%;
      border-radius: var(--rounded-xl);
      background-color: var(--color-grey-background);
    }
  }

  &__input-wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    min-height: 60px;
    max-height: 60px;
    border: 1px solid transparent;
    border-radius: var(--rounded-xl);
    font-family: var(--font-stack-secondary);
    background-color: var(--color-grey-light);

    &:focus-within {
      border: 1px solid var(--color-grey-empty);
    }

    @media screen and ($desktop) {
      min-height: 50px;
      max-height: 50px;
      border: 1px solid transparent;
      border-radius: 0;
      padding: 0 var(--space-xs);

      &:focus-within {
        border: 1px solid transparent;
      }

      &--keywords {
        border-top-left-radius: var(--rounded-xl);
        border-bottom-left-radius: var(--rounded-xl);
      }
    }
  }

  &__input {
    display: flex;
    align-items: center;
    width: 100%;
    min-height: 60px;
    max-height: 60px;
    outline: none !important;
    padding: 0 var(--space-md);
    font-size: var(--text-sm);
    font-weight: var(--font-semi);
    line-height: var(--lineheight-xs);
    color: var(--color-black);
    background-color: transparent;

    @include textOverflowEllipsis();

    &::placeholder, .placeholder {
      font-size: var(--text-xxs);
      font-weight: var(--font-medium);
      line-height: var(--lineheight-xs);
      font-style: italic;
      color: var(--color-grey-neutral);
    }
  }

  &__keywords-wrapper {
    position: relative;

    @include inputSeparator(0);
  }

  &__keywords-results-item {
    min-width: 0;
    padding: var(--space-sm) var(--space-md);
    cursor: pointer;
  }

  &__location-results-item {
    display: flex;
    align-items: center;
  }

  &__location-results-item-button {
    display: flex;
    align-items: center;
    flex: 1;
    min-width: 0;
    padding: var(--space-sm) var(--space-md);
  }

  &__location-text {
    margin-right: var(--space-xs);
    font-family: var(--font-stack-secondary);

    &--main {
      font-weight: var(--font-bold);
      font-size: var(--text-sm);

      @include textOverflowEllipsis();
    }

    &--secondary {
      flex: 1 0 0;
      color: var(--color-grey-dark);
      font-size: var(--text-xs);
      text-align: right;

      @include textOverflowEllipsis();
    }
  }

  &__calendar-wrapper {
    position: relative;
    border-radius: var(--rounded-lg);
    background-color: var(--color-white);

    @include inputSeparator(100%);
  }

  &__calendar {
    z-index: 1;
    width: 100%;
    position: absolute;
    top: calc(100% + var(--space-xs));
    border-radius: var(--rounded-lg);
    box-shadow: 0 0 10px 5px hsla(0, 0%, 0%, .05);
    background-color: var(--color-white);

    @media screen and ($desktop) {
      width: auto;
    }
  }

  &__date {
    font-weight: var(--font-semi);
  }

  &__search-button {
    grid-column: 4 / 5;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 var(--space-base);
    border-top-right-radius: var(--rounded-xl);
    border-bottom-right-radius: var(--rounded-xl);
    background-color: var(--color-grey-light);

    @media screen and ($desktop) {
      align-items: center;
    }
  }
}

</style>
