<template>
  <section class="landing-showreel__wrapper">
    <header class="landing-showreel__title">
      {{ $t('artist.dashboard.landing.video.title') }}
      <span class="landing-showreel__title-artist">
        {{ $t('artist.dashboard.landing.video.subtitle') }}
      </span>
    </header>
    <VideoMediaCard
      :maxVideoHeight="'100%'"
      :isRoundedBorders="$mediaQueries.isDesktop"
      :source="showreelURL"
      isShowControls
      autoplay
      muted>
    </VideoMediaCard>
  </section>
</template>

<script>

import VideoMediaCard     from '../../../components/molecules/VideoMediaCard/m-VideoMediaCard.vue';


export default {
  name: 'o-LandingShowreel',
  components: {
    VideoMediaCard,
  },
  props: {
    showreelURL: {
      type: String,
      required: true,
    },
  },
}

</script>

<style lang="scss">

.landing-showreel {
  &__wrapper {
    grid-row: 3 / 4;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: var(--space-xxl) 0;

    @media screen and ($desktop) {
      padding-bottom: var(--space-xl);
    }
  }

  &__title {
    display: flex;
    flex-direction: column;
    padding: 0 var(--space-md);
    margin-bottom: var(--space-lg);
    font-size: var(--text-xxl);
    font-weight: var(--font-light);
    line-height: 1;
    text-align: center;

    @media screen and ($desktop) {
      flex-direction: row;
      grid-column-gap: var(--space-xs);
    }
  }

  &__title-artist {
    font-weight: var(--font-bold);
  }
}

</style>
