<template>
  <!-- :style="{ 'background-image': `url(${currentArtistBanner.MediaURL})`}" -->
  <section class="landing-search-header__wrapper">
    <header class="landing-search-header__hero-header">
      <Heading
        :tag="'h1'"
        :level="'h1'"
        :color="'white'"
        class="landing-search-header__main-heading">
        {{ $t('artist.dashboard.landing.searchHeader.catchPhrases.main')}}
      </Heading>
      <Heading
        :tag="'h2'"
        :level="'h5'"
        :color="'white'"
        class="landing-search-header__secondary-heading">
        {{ $t('artist.dashboard.landing.searchHeader.catchPhrases.secondary')}}
      </Heading>
    </header>

    <ClientSearchBar
      isLanding
      isShowLabel
      class="landing-search-header__search-bar">
    </ClientSearchBar>
    <!-- <router-link
      v-if="!$mediaQueries.isDesktop"
      :to="{ name: 'ClientDashboardFilter', }"
      class="landing-search-header__artist-cta">
      <Button type="button">
        {{ $t('artist.dashboard.landing.searchHeader.cta') }}
      </Button>
    </router-link> -->

    <!-- TODO -->
    <div
      v-if="false"
      class="landing-search-header__hero-artist-infos">
      <Icon
        :variant="'plus-outline'"
        :color="'white'"
        class="mr-md">
      </Icon>
      <Paragraph
        :color="'white'"
        class="mr-xs">
        Michel (link)
      </Paragraph>
      <Paragraph
        :color="'white'"
        isBold>
        Chanteur et musicien (link)
      </Paragraph>
    </div>

    <!-- TODO -->
    <div
      v-if="false && $mediaQueries.isDesktop"
      class="landing-search-header__hero-slider-nav-wrapper">
      <button
        v-for="(item, index) in banners"
        @click="currentArtistBannerIndex = index"
        :key="`hero-slider-nav-item-${index}`"
        :class="[
          'landing-search-header__hero-slider-nav-item',
          { 'landing-search-header__hero-slider-nav-item--active': index === currentArtistBannerIndex },
        ]"
        type="button">
      </button>
    </div>

    <div
      class="landing-search-header__ghost-header"
      ref="ghost">
    </div>
  </section>
</template>

<script>

import { mapMutations }     from 'vuex';

import Icon                 from '../../../components/atoms/Icon/a-Icon.vue';
import Heading              from '../../../components/atoms/Heading/a-Heading.vue';
import Paragraph            from '../../../components/atoms/Paragraph/a-Paragraph.vue';
import HeroImage            from '../../../../public/assets/images/landing/hero.jpg';
import ClientSearchBar      from '../../../features/ClientDashboard/components/o-ClientSearchBar.vue';


export default {
  name: 'o-LandingSearchHeader',
  components: {
    Icon,
    Heading,
    Paragraph,
    ClientSearchBar,
  },
  props: {
    banners: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    currentArtistBannerIndex: 0,
    observer: null,
    heroImage: HeroImage,
  }),
  computed: {
    currentArtistBanner() {
      return this.banners?.[this.currentArtistBannerIndex] ?? '';
    },
  },
  mounted() {
    this.setupObserver();
  },
  beforeDestroy() {
    this.observer?.disconnect();
    this.observer = null;
  },
  methods: {
    ...mapMutations({
      toggleHeroVisibility: 'Layout/TOGGLE_LANDING_HERO_VISIBILITY',
    }),
    setupObserver() {
      this.observer = new IntersectionObserver(this.handleHeroIntersection, { threshold: 1 });
      this.observer.observe(this.$refs.ghost);
    },
    handleHeroIntersection([ entry = {} ]) {
      this.toggleHeroVisibility(entry.isIntersecting);
    },
  },
}

</script>

<style lang="scss">

.landing-search-header {
  &__wrapper {
    z-index: 100;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-rows: 50% 1fr auto;
    align-items: end;
    // TODO: Check mobile hero image cropping bug
    min-height: 90vmax;
    max-height: 90vmax;
    background: url('../../../../public/assets/images/artiste/landing-hero.jpg');
    background-position: center;
    background-size: cover;
    padding: var(--space-base);
    padding-bottom: var(--space-lg);

    &:before {
      z-index: -1;
      content: '';
      position: absolute;
      height: 100%;
      width: 100%;
      background-color: hsla(0, 0%, 0%, .25);
    }

    @media screen and ($desktop) {
      grid-template-rows: 3fr 2fr;
      align-items: center;
      min-height: 70vmin;
      max-height: 70vmin;
      padding: var(--space-xl) var(--space-xxl);
      padding-top: var(--space-xxl);
    }
  }

  &__hero-header {
    grid-column: 1 / -1;
    display: flex;
    flex-direction: column;
    justify-self: start;
    justify-content: center;
  }

  &__main-heading {
    margin-bottom: var(--space-sm);
    font-size: 48px;
    line-height: 1.1;

    @media screen and ($desktop) {
      max-width: 70%;
      margin-bottom: var(--space-md);
      font-size: 80px;
      line-height: 1;
    }
  }

  &__secondary-heading {
    font-size: 16px;

    @media screen and ($desktop) {
      font-size: 28px;
    }
  }

  &__hero-artist-infos {
    grid-row: 3 / 4;
    display: flex;
    align-items: center;

    * {
      text-shadow: 0 0 5px hsla(0, 0%, 0%, .25);
    }
  }

  &__hero-slider-nav-wrapper {
    grid-row: 3 / 4;
    display: flex;
    align-items: center;
    justify-self: end;
    width: 150px;
  }

  &__hero-slider-nav-item {
    flex: 1 0 auto;
    height: 2px;
    border-radius: var(--rounded-xs);
    background-color: var(--color-grey-neutral);

    &:not(:last-child) {
      margin-right: var(--space-xs);
    }

    &--active {
      flex: 2 0 auto;
      background-color: var(--color-white);
    }
  }

  &__search-bar {
    grid-column: 1 / -1;
    align-self: center;
  }

  &__artist-cta {
    grid-row: 2 / 3;
    grid-column: 1 / -1;
    justify-self: center;
    align-self: center;

    button {
      padding: var(--space-lg) var(--space-xxl);
      margin-top: var(--space-lg);
    }
  }

  &__ghost-header {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 70px;
    visibility: hidden;
  }
}
</style>
